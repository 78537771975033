export enum GoodsProdctCodeEnum {
    CALENDAR_2024 = "CALENDAR_2024",
    GSSKHND = "GSSKHND", // 효자손
    KEYRING = "KEYRING",
    ACRYLIC = "ACRYLIC",
  }
  
  // 제휴 인쇄업체 코드
  export enum PrintingPartnerEnum {
    REDPRINTING = "REDPRINTING",
    PHOTOMON = "PHOTOMON",
    STOPBOOK = "STOPBOOK",
    BLANKER = "BLANKER",
    JUMBO = "JUMBO",
    HUSK = "HUSK",
    FUJI = "FUJI",
    CUSTOMX = "CUSTOMX",
    DEKKANG = "DEKKANG",
  }
  
  // 제휴 인쇄업체 한글 텍스트
  export enum PrintingPartnerLabelEnum {
    REDPRINTING = "레드프린팅",
    PHOTOMON = "포토몬",
    STOPBOOK = "스탑북",
    BLANKER = "블랭커",
    JUMBO = "점보",
    HUSK = "허스크",
    FUJI = "후지",
    CUSTOMX = "커스텀엑스",
    DEKKANG = "디자인깡패",
  }